<template>
  <div>
    <div>
      <b-row>
        <b-col cols="6">
          <b-button-group class="so-status order-neg1">
            <b-form-radio-group buttons button-variant="secondary">
              <b-form-radio-group
                id="e-pay-radio"
                v-model="selected"
                :options="radioOptions"
                button-variant="secondary"
                size="md"
                buttons
              ></b-form-radio-group>
            </b-form-radio-group>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" style="margin-top: 5px">
          <b-input-group prepend="$">
            <b-form-input
              ref="input"
              v-model="requestedAmount"
              v-currency="currencyOptions"
              :state="requestedAmount < 0 ? false : null"
              placeholder="Amount"
            />
            <div v-if="requestedAmount < 0" class="error">Must be greater than 0</div>
          </b-input-group>
        </b-col>
        <b-col v-show="selected == 1" cols="5" style="margin-top: 5px; margin-left: -20px">
          <b-form-input
            ref="input"
            v-model="$v.ePayEmail.$model"
            placeholder="Email Address"
            :state="!$v.ePayEmail.$error ? null : false"
          />
          <div v-show="$v.ePayEmail.$error && !$v.ePayEmail.email" class="error">Enter a valid Email Address.</div>
        </b-col>
        <b-col v-show="selected == 2" cols="5" style="margin-top: 10px; margin-left: -20px">
          <div>Send transaction to Card Reader</div>
        </b-col>
        <b-col cols="2" style="float: right; margin-left: -20px; margin-top: 5px">
          <b-button
            :disabled="
              (selected == 1 && (ePayEmail == null || ePayEmail.trim() == '' || $v.ePayEmail.$error)) ||
              order.total == 0 ||
              requestedAmount == null ||
              requestedAmount.trim() == '' ||
              requestedAmount <= 0 ||
              sending
            "
            class="btn-primary"
            @click="send"
          >
            <div v-show="sending">
              <b-spinner small></b-spinner>
              Sending...
            </div>
            <div v-show="!sending">Send</div>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <div v-for="(item, index) in ePayRequests.slice().reverse()" :key="index">
            <div v-if="item.ePayRequestType == 1">
              <b-badge v-if="item.ePayRequestStatus == 1" variant="primary" class="mr-1">Sent</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 2" variant="success" class="mr-1">Paid</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 3" variant="danger" class="mr-1">Declined</b-badge>
              In person {{ item.dateCreate | dateTime }} to
              <strong>Card Terminal</strong>
              : {{ getEmployee(item.empIdCreate) }} Amount: {{ item.requestedAmount | currency }}
            </div>
            <div v-if="item.ePayRequestType == 2">
              <b-badge v-if="item.ePayRequestStatus == 1" variant="primary" class="mr-1">Sent</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 2" variant="success" class="mr-1">Paid</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 3" variant="danger" class="mr-1">Declined</b-badge>
              Email {{ item.dateCreate | dateTime }} to
              <strong>{{ item.ePayEmail }}</strong>
              : {{ getEmployee(item.empIdCreate) }} Amount: {{ item.requestedAmount | currency }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      title="Save Changes"
      message="There are unsaved changes. You must save before sending payment notification."
      continue-btn-text="Save changes"
      cancel-btn-text="Cancel"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ServiceOrderGetters, ServiceOrderActions } from '@/shared/store/service-order/types';
import { PartsOrderActions } from '@/shared/store/parts-order/types';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import { email } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import { LookupGetters } from '@/shared/store/lookup/types';

export default {
  name: 'EPayComponent',
  components: {
    'unsaved-changes-modal': UnsavedChangesModal
  },
  props: {
    orderId: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ePayEmail: null,
      path: this.$route.path,
      sending: false,
      selected: 1,
      requestedAmount: null,
      currencyOptions: {
        currency: null,
        locale: 'en-US'
      }
    };
  },
  validations: {
    ePayEmail: {
      email
    }
  },
  computed: {
    ...mapGetters({
      //allowEPay: ServiceOrderGetters.GET_ALLOW_E_PAY,
      // allowEPayInPerson: ServiceOrderGetters.GET_ALLOW_E_PAY_IN_PERSON,
      //serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      //serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      //partOrder: PartOrderGetters.GET_PART_ORDER,
      //partOrderId: PartOrderGetters.GET_PART_ORDER_ID,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      ePayStatusList: LookupGetters.GET_EPAY_STATUSES_LIST,
      ePayTypeList: LookupGetters.GET_EPAY_TYPES_LIST,
      branchId: ServiceOrderGetters.GET_BRANCH_ID
    }),
    ...mapGetters([ServiceOrderGetters.HAS_CHANGES]),
    unitId() {
      return this.$router.currentRoute.params.unitId;
    },
    hasChanges() {
      if (!this.isPartOrder) return this[ServiceOrderGetters.HAS_CHANGES];
      else return null;
    },
    ePayRequests() {
      return this.order.ePayRequests;
    },
    radioOptions() {
      var options = [];
      var allowedOptions = this.ePayTypeList;
      if (!this.order.allowEPayInPerson) {
        allowedOptions = allowedOptions.filter(x => x.description != 'In Person');
      }
      if (!this.order.allowEPay) {
        allowedOptions = allowedOptions.filter(x => x.description != 'Email');
      }
      allowedOptions.map(requestType => {
        var option = { text: requestType.description, value: requestType.ePayTypeId };
        options.push(option);
      });
      return options;
    },
    isPartOrder() {
      if (this.orderId.substring(0, 1) == 'C') return true;
      else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions([PartsOrderActions.SEND_PAYMENT_REQUEST]),
    ...mapActions([PartsOrderActions.SEND_CARD_PAYMENT_REQUEST]),
    ...mapActions([ServiceOrderActions.SEND_PAYMENT_REQUEST]),
    ...mapActions([ServiceOrderActions.SEND_CARD_PAYMENT_REQUEST]),
    getEmployee(empId) {
      return this.employeeList.find(employee => employee.employeeId === empId)?.name;
    },
    async send() {
      //if (this.hasChanges()) {
      //  this.$refs.UnsavedChangesModal.show(this, this.save);
      //} else {
      if (this.selected == 1) {
        this.sending = true;
        try {
          if (this.isPartOrder)
            await this[PartsOrderActions.SEND_PAYMENT_REQUEST]({
              orderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          else {
            await this[ServiceOrderActions.SEND_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          }
          this.ePayEmail = null;
          this.requestedAmount = null;
          SuccessService.createSuccessToast(this.$root, `Payment request sent successfully.`);
        } catch {
          const errorMessage = `Error sending payment request.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sending = false;
        }
      } else if (this.selected == 2) {
        this.sending = true;
        try {
          if (this.isPartOrder) {
            await this[PartsOrderActions.SEND_CARD_PAYMENT_REQUEST]({
              orderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          } else {
            await this[ServiceOrderActions.SEND_CARD_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.branchId
            });
          }
          this.requestedAmount = null;
          SuccessService.createSuccessToast(this.$root, `Payment sent to card reader successfully.`);
        } catch {
          const errorMessage = `Error card present payment.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sending = false;
        }
      }
      //}
    },
    async save(cancel = true) {
      if (!cancel) {
        this.$emit('updateServiceOrder');
      }
    }
  }
};
</script>
